import * as React from 'react';
import Header from '../components/header';
import SEO, { SeoModel } from '../components/seo';
import Helmet from 'react-helmet';
import Footer from '../components/footer';

interface ShopPageProps {
  location: Location,
}

export default class ShopPage extends React.Component<ShopPageProps> {

  public render() {

    const seo: SeoModel = {
      title: 'Shop'
    };

    return (
      <div id="top" className="d-flex flex-column w-100 h-100 mainBackground">
        <Header />
        <SEO model={seo} />
        <div className="mt-5" />
        <section id="shop" className={`bg-light pt-4 pb-0`}>
          <h2><a href="https://shop.jubal.org">Naar de Jubal Shop.</a></h2>
        </section>
      </div>
    );

    return (
      <div id="top" className="d-flex flex-column w-100 h-100 mainBackground">
        <Header />
        <SEO model={seo} />
        <div className="mt-5" />
        <section id="shop" className={`bg-light pt-4 pb-0`}>
          <div id="shop-container">
            <a href="https://shop.spreadshirt.nl/JubalDordrecht">JubalDordrecht</a>
          </div>

          <Helmet>
          <script>
            {`var spread_shop_config = {
              shopName: 'JubalDordrecht',
              locale: 'nl_NL',
              prefix: 'https://shop.spreadshirt.nl',
              baseId: 'shop-container'
            };`}
          </script>

          <script
            type="text/javascript"
            src="https://shop.spreadshirt.nl/shopfiles/shopclient/shopclient.nocache.js"
          />
          </Helmet>
        </section>
        <Footer isSitemapHidden={true} location={this.props.location} />
      </div>
    );
  }
}
